:global .Calendar-0 {
  --filter-color: var(--calendar-1);
  --color: #fff;
}
:global .Calendar-1 {
  --filter-color: var(--calendar-2);
  --color: #fff;
}
:global .Calendar-2 {
  --filter-color: var(--calendar-3);
  --color: #fff;
}
:global .Calendar-3 {
  --filter-color: var(--calendar-4);
  --color: #fff;
}

.CalendarFilter {
  padding: 10px;
  display: flex;
}

.CalendarFilter:hover {
  background-color: var(--design-component-2);
}

.CalendarFilter :global(.dropdown) {
  display: none;
  cursor: pointer;
  font-size: 1em;
}

.CalendarFilter:hover :global(.dropdown) {
  display: block;
}

.CalendarFilter span.text {
  flex: 1;
}

.dropdown {
  z-index: 3;
}

.DropdownTitle {
  display: flex;
  align-items: center;
}

.DropdownTitle svg {
  margin-right: 0.75em;
  font-size: 1.5em;
}

.filter {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--background);
  border: 2px solid var(--filter-color);
  margin-right: 10px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  fill: transparent;
}

.activeFilter {
  background-color: var(--filter-color);
  fill: var(--color);
}

.colors {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 0.6em;
  grid-row-gap: 0.6em;
}

.colors div.active {
  fill: var(--color);
}

.colors div:hover {
  color: var(--color);
}

.colors div {
  color: transparent;
  background-color: var(--backgroundColor);
  display: flex;
  width: 1.2em;
  height: 1.2em;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
}

@media (min-width: 320px) and (max-width: 799px) {
  .CalendarFilter :global(.dropdown) {
    display: block;
  }
}
