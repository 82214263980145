@import url(https://fonts.googleapis.com/css?family=Merriweather:300,400,700|Nunito:300,400,700|Open+Sans:300,400,700|Roboto:300,400,700&display=swap);
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --classicSeance: #7f1d86;
  --darkSeance: #9e7daa;
  --classicFountainBlue: #63acbe;
  --darkFountainBlue: #95def1;
  --classicOutrageousOrange: #fd612c;
  --darkOutrageousOrange: #fd8f6b;
  --classicWaikawaGray: #5d6da4;
  --darkWaikawaGray: #8d9bd6;
}

.Seance.Classic {
  --primary: var(--classicSeance);
  --primary-variant: #54116e;
  --secondary: #63acbe;
  --secondary-variant: #95def1;
  --background: #ffffff;
  --error: #b00020;
  --text-1: #000000;
  --text-2: #6a6a6a;
  --text-3: #9f9f9f;
  --design-component-1: #e0e0e0;
  --design-component-2: #f4f4f4;
  --design-component-3: #919191;
  --design-component-4: #b4b4b4;
  --notification-1: #c835a9;
  --notification-2: #c49000;
  --calendar-1: #005ab5;
  --calendar-2: #006340;
  --calendar-3: #fe6100;
  --calendar-4: #aebc00;
}

.Seance.Dark {
  --primary: var(--darkSeance);
  --primary-variant: #5b415b;
  --secondary: #95def1;
  --secondary-variant: #63acbe;
  --background: #121212;
  --error: #cf6679;
  --text-1: #bbbbbb;
  --text-2: #a9a9a9;
  --text-3: #646464;
  --design-component-1: #2b2b2b;
  --design-component-2: #1d1d1d;
  --design-component-3: #a9a9a9;
  --design-component-4: #b4b4b4;
  --notification-1: #edbce0;
  --notification-2: #c49000;
  --calendar-1: #4eb2fe;
  --calendar-2: #02b181;
  --calendar-3: #ffb54c;
  --calendar-4: #d4e948;
}

.FountainBlue.Classic {
  --primary: var(--classicFountainBlue);
  --primary-variant: #95def1;
  --secondary: #7f1d86;
  --secondary-variant: #54116e;
  --background: #ffffff;
  --error: #b00020;
  --text-1: #000000;
  --text-2: #6a6a6a;
  --text-3: #9f9f9f;
  --design-component-1: #e0e0e0;
  --design-component-2: #f4f4f4;
  --design-component-3: #919191;
  --design-component-4: #b4b4b4;
  --notification-1: #a55fb7;
  --notification-2: #c49000;
  --calendar-1: #005ab5;
  --calendar-2: #785ef0;
  --calendar-3: #fe6100;
  --calendar-4: #aebc00;
}

.FountainBlue.Dark {
  --primary: var(--darkFountainBlue);
  --primary-variant: #63acbe;
  --secondary: #c3b1ca;
  --secondary-variant: #5b415b;
  --background: #121212;
  --error: #cf6679;
  --text-1: #bbbbbb;
  --text-2: #a9a9a9;
  --text-3: #646464;
  --design-component-1: #2b2b2b;
  --design-component-2: #1d1d1d;
  --design-component-3: #a9a9a9;
  --design-component-4: #b4b4b4;
  --notification-1: #a55fb7;
  --notification-2: #c49000;
  --calendar-1: #4eb2fe;
  --calendar-2: #b4a3f6;
  --calendar-3: #ffb54c;
  --calendar-4: #d4e948;
}

.OutrageousOrange.Classic {
  --primary: var(--classicOutrageousOrange);
  --primary-variant: #bc4119;
  --secondary: #63acbe;
  --secondary-variant: #95def1;
  --background: #ffffff;
  --error: #b00020;
  --text-1: #000000;
  --text-2: #6a6a6a;
  --text-3: #9f9f9f;
  --design-component-1: #e0e0e0;
  --design-component-2: #f4f4f4;
  --design-component-3: #919191;
  --design-component-4: #b4b4b4;
  --notification-1: #a55fb7;
  --notification-2: #c49000;
  --calendar-1: #005ab5;
  --calendar-2: #785ef0;
  --calendar-3: #006340;
  --calendar-4: #aebc00;
}

.OutrageousOrange.Dark {
  --primary: var(--darkOutrageousOrange);
  --primary-variant: #bc4119;
  --secondary: #95def1;
  --secondary-variant: #63acbe;
  --background: #121212;
  --error: #cf6679;
  --text-1: #bbbbbb;
  --text-2: #a9a9a9;
  --text-3: #646464;
  --design-component-1: #2b2b2b;
  --design-component-2: #1d1d1d;
  --design-component-3: #a9a9a9;
  --design-component-4: #b4b4b4;
  --notification-1: #a55fb7;
  --notification-2: #c49000;
  --calendar-1: #4eb2fe;
  --calendar-2: #b4a3f6;
  --calendar-3: #02b181;
  --calendar-4: #d4e948;
}

.WaikawaGray.Classic {
  --primary: var(--classicWaikawaGray);
  --primary-variant: #2e4275;
  --secondary: #fbc02d;
  --secondary-variant: #fbc02d;
  --background: #ffffff;
  --error: #b00020;
  --text-1: #000000;
  --text-2: #6a6a6a;
  --text-3: #9f9f9f;
  --design-component-1: #e0e0e0;
  --design-component-2: #f4f4f4;
  --design-component-3: #919191;
  --design-component-4: #b4b4b4;
  --notification-1: #c4dfe6;
  --notification-2: #c7afbd;
  --calendar-1: #65a4ac;
  --calendar-2: #377ea0;
  --calendar-3: #ce5a58;
  --calendar-4: #aebc00;
}

.WaikawaGray.Dark {
  --primary: var(--darkWaikawaGray);
  --primary-variant: #2e4275;
  --secondary: #fbc02d;
  --secondary-variant: #fff59d;
  --background: #121212;
  --error: #cf6679;
  --text-1: #bbbbbb;
  --text-2: #a9a9a9;
  --text-3: #646464;
  --design-component-1: #2b2b2b;
  --design-component-2: #1d1d1d;
  --design-component-3: #a9a9a9;
  --design-component-4: #b4b4b4;
  --notification-1: #c4dfe6;
  --notification-2: #c7afbd;
  --calendar-1: #40c2ca;
  --calendar-2: #52c0ff;
  --calendar-3: #db7e80;
  --calendar-4: #d4e948;
}

.Flex {
  display: flex;
  align-items: center;
  flex-flow: column;
}

.Day {
  padding: 2px;
  width: 1.4em;
  height: 1.4em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px auto;
}

.fc-timeGridDay-view {
  font-size: 0.97em;
}

.fc-time-grid-event.fc-short .fc-time span {
  display: inline-block;
  font-weight: bold;
  /* don't display the full time text... */
}

.fc-time-grid-event.fc-short .fc-time:before,
.fc-time-grid-event.fc-short .fc-time:after {
  content: '';
  display: none;
}

.fc .Today {
  background-color: var(--primary);
  border-radius: 50%;
  color: var(--background);
}

.fc .fc-row .fc-content-skeleton td.fc-day-top {
  text-align: center;
  padding: 10px 0;
  /* height: 35px; */
}

.fc-ltr .fc-dayGrid-view .fc-day-top .fc-day-number {
  float: none;
  width: 1.4em;
  height: 1.4em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  /* align-self: center; */
}

/* .fc-ltr .fc-dayGrid-view .fc-day-top.fc-today .fc-day-number {
  border-radius: 50%;
  background-color: var(--primary);
  color: var(--background);
} */

/* .fc-unthemed td.fc-today {
  background-color: var(--background);
} */

.fc-ltr .fc-time-grid .fc-now-indicator-arrow {
  display: none;
}

.fc-time-grid .fc-slats .fc-minor td {
  border-top: none;
}
.fc-timeGridWeek-view .fc-body td.fc-widget-content,
.fc-timeGridDay-view .fc-body td.fc-widget-content {
  border-left: none;
}
.fc-timeGridWeek-view > table > tbody.fc-body > tr > td.fc-widget-content,
.fc-timeGridDay-view > table > tbody.fc-body > tr > td.fc-widget-content {
  border-right: none;
}

.fc-head-container.fc-widget-header {
  border-left: 1px solid transparent;
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
}

.fc .fc-row .fc-content-skeleton table,
.fc .fc-row .fc-content-skeleton td,
.fc .fc-row .fc-mirror-skeleton td {
  padding: 0 3px;
}

.fc-event {
  border: none;
  text-indent: 4px;
  font-size: 1em;
  padding: 2px;
  cursor: pointer;
}

.fc-button {
  padding: 0.1em 0.45em;
  background-color: var(--design-component-2);
  color: var(--design-component-3);
  border: var(--design-component-4);
}

.fc-button-primary:not(:disabled):active,
.fc-button-primary:not(:disabled).fc-button-active,
.fc-button-primary:disabled,
.fc-button-primary:hover {
  background-color: var(--design-component-3);
  color: var(--background);
  border-color: var(--design-component-4);
}

.fc-today-button {
  background: var(--background) !important;
  color: var(--text-2) !important;
}

.fc-other-month {
  color: var(--text-3);
  opacity: 0.5 !important;
}

@media (min-width: 320px) and (max-width: 799px) {
  .fc-toolbar h2 {
    font-size: 1em;
  }

  .FlexBox .fc {
    font-size: 0.7em;
  }
}

.fc-scroller.fc-time-grid-container,
.fc-scroller.fc-day-grid-container {
  height: unset !important;
}

/**
* Our Styles
*/

/* .Filters {
  list-style: none;
  padding-left: 0;
} */

.dropdown {
  padding: 0;
  background: none;
  box-shadow: none;
  border: none;
  outline: none;
}

.dropdownHolder {
  border: 1px solid gray;
  box-shadow: 0 0 3px gray;
  border-radius: 0.2em;
}

/* .FlexBox {
  display: flex;
  flex-flow: row;
} */

/* .FlexBox .items {
  flex: 1;
} */

/* .FlexBox .fc {
  margin-left: 20px;
  flex: 3;
} */

/* .FlexBox .items > div {
  padding: 20px 0;
  border-bottom: 1px solid var(text-3);
} */

/* .FlexBox .items > div:last-child {
  border-bottom: none;
} */

/* .FlexBox .items .newEvent {
  padding: 20px;
} */

/* .FlexBox .items .newEvent button {
  background: none;
  border: none;
  color: var(--primary);
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
} */

/**
* Small Calendar
*/

.react-calendar {
  border: none;
  background: var(--background);
  font-family: inherit;
}

.react-calendar__navigation__label {
  font-family: inherit;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-calendar__tile abbr {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2em;
  width: 2em;
}

/* .react-calendar__tile:enabled:hover {
  background-color: var(--design-component-2);
  color: var(--text-1);
} */
/* .react-calendar__tile--active:enabled:focus,
.react-calendar__tile--active {
  background: blue;
  color: var(--design-component-1);
} */

.react-calendar__tile--active:enabled:focus abbr,
.react-calendar__tile--active abbr {
  background-color: var(--design-component-1);
  border-radius: 50%;
  color: var(--text-1);
  /* padding: 0.5em; */
}

.react-calendar .Today abbr {
  background-color: var(--primary);
  border-radius: 50%;
  color: var(--background);
  padding: 0.5em;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--text-3) !important;
  opacity: 0.5;
}

.react-calendar__month-view__days__day,
.react-calendar__month-view__days__day--weekend {
  color: var(--text-1);
  font-family: inherit;
}

@media (min-width: 320px) and (max-width: 799px) {
  /* .FlexBox {
    flex-flow: column;
  } */

  /* .FlexBox .items > div {
    border-bottom: none;
    margin-bottom: 5px;
    padding: 0;
  } */

  /* .FlexBox .items .newEvent {
    padding: 5px;
  } */

  .react-calendar {
    display: none;
  }
}

.Dropdown_DropdownHolder__3NwKO {
  position: relative;
}

.Dropdown_Dropdown__2p7zG {
  position: absolute;
  width: -webkit-max-content;
  width: max-content;
  right: 5px;
  background-color: #f4f4f4;
  color: black;
  /* border: 1px solid black; */
  padding: 20px;
  z-index: 2;
  top: 1em;
}

.Dropdown_Dropdown__2p7zG.Dropdown_forNav__2qbnb {
  top: 30px;
}

/* .Dropdown:after,
.Dropdown:before {
  bottom: 100%;
  right: 0;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.Dropdown:after {
  border-color: transparent;
  border-bottom-color: #fff;
  border-width: 15px;
  margin-left: -15px;
}
.Dropdown:before {
  border-color: transparent;
  border-bottom-color: black;
  border-width: 16px;
  margin-left: -16px;
} */

.Dropdown_Dropdown__2p7zG > * {
  display: block;
  color: black;
  text-decoration: none;
  padding: 10px 0;
  border-bottom: 1px solid #b4b4b4;
  font-weight: 400;
}

.Dropdown_Dropdown__2p7zG *:first-child {
  padding-top: 0;
}

.Dropdown_Dropdown__2p7zG *:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.Dropdown_Dropdown__2p7zG a.active {
  color: #5d6da4;
  font-weight: bold;
}

@media (min-width: 320px) and (max-width: 799px) {
  .Dropdown_Dropdown__2p7zG {
    right: -10px;
  }
}

.Calendar-0 {
  --filter-color: var(--calendar-1);
  --color: #fff;
}
.Calendar-1 {
  --filter-color: var(--calendar-2);
  --color: #fff;
}
.Calendar-2 {
  --filter-color: var(--calendar-3);
  --color: #fff;
}
.Calendar-3 {
  --filter-color: var(--calendar-4);
  --color: #fff;
}

.CalendarFilter_CalendarFilter__1y3h7 {
  padding: 10px;
  display: flex;
}

.CalendarFilter_CalendarFilter__1y3h7:hover {
  background-color: var(--design-component-2);
}

.CalendarFilter_CalendarFilter__1y3h7 .dropdown {
  display: none;
  cursor: pointer;
  font-size: 1em;
}

.CalendarFilter_CalendarFilter__1y3h7:hover .dropdown {
  display: block;
}

.CalendarFilter_CalendarFilter__1y3h7 span.CalendarFilter_text__33KVX {
  flex: 1 1;
}

.CalendarFilter_dropdown__Fgtyv {
  z-index: 3;
}

.CalendarFilter_DropdownTitle__4bd6u {
  display: flex;
  align-items: center;
}

.CalendarFilter_DropdownTitle__4bd6u svg {
  margin-right: 0.75em;
  font-size: 1.5em;
}

.CalendarFilter_filter__2HKOM {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--background);
  border: 2px solid var(--filter-color);
  margin-right: 10px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  fill: transparent;
}

.CalendarFilter_activeFilter__2IKe- {
  background-color: var(--filter-color);
  fill: var(--color);
}

.CalendarFilter_colors__380RZ {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 0.6em;
  grid-row-gap: 0.6em;
}

.CalendarFilter_colors__380RZ div.CalendarFilter_active__31M3T {
  fill: var(--color);
}

.CalendarFilter_colors__380RZ div:hover {
  color: var(--color);
}

.CalendarFilter_colors__380RZ div {
  color: transparent;
  background-color: var(--backgroundColor);
  display: flex;
  width: 1.2em;
  height: 1.2em;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
}

@media (min-width: 320px) and (max-width: 799px) {
  .CalendarFilter_CalendarFilter__1y3h7 .dropdown {
    display: block;
  }
}

