.Flex {
  display: flex;
  align-items: center;
  flex-flow: column;
}

.Day {
  padding: 2px;
  width: 1.4em;
  height: 1.4em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px auto;
}

.fc-timeGridDay-view {
  font-size: 0.97em;
}

.fc-time-grid-event.fc-short .fc-time span {
  display: inline-block;
  font-weight: bold;
  /* don't display the full time text... */
}

.fc-time-grid-event.fc-short .fc-time:before,
.fc-time-grid-event.fc-short .fc-time:after {
  content: '';
  display: none;
}

.fc .Today {
  background-color: var(--primary);
  border-radius: 50%;
  color: var(--background);
}

.fc .fc-row .fc-content-skeleton td.fc-day-top {
  text-align: center;
  padding: 10px 0;
  /* height: 35px; */
}

.fc-ltr .fc-dayGrid-view .fc-day-top .fc-day-number {
  float: none;
  width: 1.4em;
  height: 1.4em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  /* align-self: center; */
}

/* .fc-ltr .fc-dayGrid-view .fc-day-top.fc-today .fc-day-number {
  border-radius: 50%;
  background-color: var(--primary);
  color: var(--background);
} */

/* .fc-unthemed td.fc-today {
  background-color: var(--background);
} */

.fc-ltr .fc-time-grid .fc-now-indicator-arrow {
  display: none;
}

.fc-time-grid .fc-slats .fc-minor td {
  border-top: none;
}
.fc-timeGridWeek-view .fc-body td.fc-widget-content,
.fc-timeGridDay-view .fc-body td.fc-widget-content {
  border-left: none;
}
.fc-timeGridWeek-view > table > tbody.fc-body > tr > td.fc-widget-content,
.fc-timeGridDay-view > table > tbody.fc-body > tr > td.fc-widget-content {
  border-right: none;
}

.fc-head-container.fc-widget-header {
  border-left: 1px solid transparent;
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
}

.fc .fc-row .fc-content-skeleton table,
.fc .fc-row .fc-content-skeleton td,
.fc .fc-row .fc-mirror-skeleton td {
  padding: 0 3px;
}

.fc-event {
  border: none;
  text-indent: 4px;
  font-size: 1em;
  padding: 2px;
  cursor: pointer;
}

.fc-button {
  padding: 0.1em 0.45em;
  background-color: var(--design-component-2);
  color: var(--design-component-3);
  border: var(--design-component-4);
}

.fc-button-primary:not(:disabled):active,
.fc-button-primary:not(:disabled).fc-button-active,
.fc-button-primary:disabled,
.fc-button-primary:hover {
  background-color: var(--design-component-3);
  color: var(--background);
  border-color: var(--design-component-4);
}

.fc-today-button {
  background: var(--background) !important;
  color: var(--text-2) !important;
}

.fc-other-month {
  color: var(--text-3);
  opacity: 0.5 !important;
}

@media (min-width: 320px) and (max-width: 799px) {
  .fc-toolbar h2 {
    font-size: 1em;
  }

  .FlexBox .fc {
    font-size: 0.7em;
  }
}

.fc-scroller.fc-time-grid-container,
.fc-scroller.fc-day-grid-container {
  height: unset !important;
}
