.DropdownHolder {
  position: relative;
}

.Dropdown {
  position: absolute;
  width: max-content;
  right: 5px;
  background-color: #f4f4f4;
  color: black;
  /* border: 1px solid black; */
  padding: 20px;
  z-index: 2;
  top: 1em;
}

.Dropdown.forNav {
  top: 30px;
}

/* .Dropdown:after,
.Dropdown:before {
  bottom: 100%;
  right: 0;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.Dropdown:after {
  border-color: transparent;
  border-bottom-color: #fff;
  border-width: 15px;
  margin-left: -15px;
}
.Dropdown:before {
  border-color: transparent;
  border-bottom-color: black;
  border-width: 16px;
  margin-left: -16px;
} */

.Dropdown > * {
  display: block;
  color: black;
  text-decoration: none;
  padding: 10px 0;
  border-bottom: 1px solid #b4b4b4;
  font-weight: 400;
}

.Dropdown *:first-child {
  padding-top: 0;
}

.Dropdown *:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.Dropdown :global a.active {
  color: #5d6da4;
  font-weight: bold;
}

@media (min-width: 320px) and (max-width: 799px) {
  .Dropdown {
    right: -10px;
  }
}
