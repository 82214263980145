/**
* Our Styles
*/

/* .Filters {
  list-style: none;
  padding-left: 0;
} */

.dropdown {
  padding: 0;
  background: none;
  box-shadow: none;
  border: none;
  outline: none;
}

.dropdownHolder {
  border: 1px solid gray;
  box-shadow: 0 0 3px gray;
  border-radius: 0.2em;
}

/* .FlexBox {
  display: flex;
  flex-flow: row;
} */

/* .FlexBox .items {
  flex: 1;
} */

/* .FlexBox .fc {
  margin-left: 20px;
  flex: 3;
} */

/* .FlexBox .items > div {
  padding: 20px 0;
  border-bottom: 1px solid var(text-3);
} */

/* .FlexBox .items > div:last-child {
  border-bottom: none;
} */

/* .FlexBox .items .newEvent {
  padding: 20px;
} */

/* .FlexBox .items .newEvent button {
  background: none;
  border: none;
  color: var(--primary);
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
} */

/**
* Small Calendar
*/

.react-calendar {
  border: none;
  background: var(--background);
  font-family: inherit;
}

.react-calendar__navigation__label {
  font-family: inherit;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-calendar__tile abbr {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2em;
  width: 2em;
}

/* .react-calendar__tile:enabled:hover {
  background-color: var(--design-component-2);
  color: var(--text-1);
} */
/* .react-calendar__tile--active:enabled:focus,
.react-calendar__tile--active {
  background: blue;
  color: var(--design-component-1);
} */

.react-calendar__tile--active:enabled:focus abbr,
.react-calendar__tile--active abbr {
  background-color: var(--design-component-1);
  border-radius: 50%;
  color: var(--text-1);
  /* padding: 0.5em; */
}

.react-calendar .Today abbr {
  background-color: var(--primary);
  border-radius: 50%;
  color: var(--background);
  padding: 0.5em;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--text-3) !important;
  opacity: 0.5;
}

.react-calendar__month-view__days__day,
.react-calendar__month-view__days__day--weekend {
  color: var(--text-1);
  font-family: inherit;
}

@media (min-width: 320px) and (max-width: 799px) {
  /* .FlexBox {
    flex-flow: column;
  } */

  /* .FlexBox .items > div {
    border-bottom: none;
    margin-bottom: 5px;
    padding: 0;
  } */

  /* .FlexBox .items .newEvent {
    padding: 5px;
  } */

  .react-calendar {
    display: none;
  }
}
