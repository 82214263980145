@import url('https://fonts.googleapis.com/css?family=Merriweather:300,400,700|Nunito:300,400,700|Open+Sans:300,400,700|Roboto:300,400,700&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --classicSeance: #7f1d86;
  --darkSeance: #9e7daa;
  --classicFountainBlue: #63acbe;
  --darkFountainBlue: #95def1;
  --classicOutrageousOrange: #fd612c;
  --darkOutrageousOrange: #fd8f6b;
  --classicWaikawaGray: #5d6da4;
  --darkWaikawaGray: #8d9bd6;
}

.Seance.Classic {
  --primary: var(--classicSeance);
  --primary-variant: #54116e;
  --secondary: #63acbe;
  --secondary-variant: #95def1;
  --background: #ffffff;
  --error: #b00020;
  --text-1: #000000;
  --text-2: #6a6a6a;
  --text-3: #9f9f9f;
  --design-component-1: #e0e0e0;
  --design-component-2: #f4f4f4;
  --design-component-3: #919191;
  --design-component-4: #b4b4b4;
  --notification-1: #c835a9;
  --notification-2: #c49000;
  --calendar-1: #005ab5;
  --calendar-2: #006340;
  --calendar-3: #fe6100;
  --calendar-4: #aebc00;
}

.Seance.Dark {
  --primary: var(--darkSeance);
  --primary-variant: #5b415b;
  --secondary: #95def1;
  --secondary-variant: #63acbe;
  --background: #121212;
  --error: #cf6679;
  --text-1: #bbbbbb;
  --text-2: #a9a9a9;
  --text-3: #646464;
  --design-component-1: #2b2b2b;
  --design-component-2: #1d1d1d;
  --design-component-3: #a9a9a9;
  --design-component-4: #b4b4b4;
  --notification-1: #edbce0;
  --notification-2: #c49000;
  --calendar-1: #4eb2fe;
  --calendar-2: #02b181;
  --calendar-3: #ffb54c;
  --calendar-4: #d4e948;
}

.FountainBlue.Classic {
  --primary: var(--classicFountainBlue);
  --primary-variant: #95def1;
  --secondary: #7f1d86;
  --secondary-variant: #54116e;
  --background: #ffffff;
  --error: #b00020;
  --text-1: #000000;
  --text-2: #6a6a6a;
  --text-3: #9f9f9f;
  --design-component-1: #e0e0e0;
  --design-component-2: #f4f4f4;
  --design-component-3: #919191;
  --design-component-4: #b4b4b4;
  --notification-1: #a55fb7;
  --notification-2: #c49000;
  --calendar-1: #005ab5;
  --calendar-2: #785ef0;
  --calendar-3: #fe6100;
  --calendar-4: #aebc00;
}

.FountainBlue.Dark {
  --primary: var(--darkFountainBlue);
  --primary-variant: #63acbe;
  --secondary: #c3b1ca;
  --secondary-variant: #5b415b;
  --background: #121212;
  --error: #cf6679;
  --text-1: #bbbbbb;
  --text-2: #a9a9a9;
  --text-3: #646464;
  --design-component-1: #2b2b2b;
  --design-component-2: #1d1d1d;
  --design-component-3: #a9a9a9;
  --design-component-4: #b4b4b4;
  --notification-1: #a55fb7;
  --notification-2: #c49000;
  --calendar-1: #4eb2fe;
  --calendar-2: #b4a3f6;
  --calendar-3: #ffb54c;
  --calendar-4: #d4e948;
}

.OutrageousOrange.Classic {
  --primary: var(--classicOutrageousOrange);
  --primary-variant: #bc4119;
  --secondary: #63acbe;
  --secondary-variant: #95def1;
  --background: #ffffff;
  --error: #b00020;
  --text-1: #000000;
  --text-2: #6a6a6a;
  --text-3: #9f9f9f;
  --design-component-1: #e0e0e0;
  --design-component-2: #f4f4f4;
  --design-component-3: #919191;
  --design-component-4: #b4b4b4;
  --notification-1: #a55fb7;
  --notification-2: #c49000;
  --calendar-1: #005ab5;
  --calendar-2: #785ef0;
  --calendar-3: #006340;
  --calendar-4: #aebc00;
}

.OutrageousOrange.Dark {
  --primary: var(--darkOutrageousOrange);
  --primary-variant: #bc4119;
  --secondary: #95def1;
  --secondary-variant: #63acbe;
  --background: #121212;
  --error: #cf6679;
  --text-1: #bbbbbb;
  --text-2: #a9a9a9;
  --text-3: #646464;
  --design-component-1: #2b2b2b;
  --design-component-2: #1d1d1d;
  --design-component-3: #a9a9a9;
  --design-component-4: #b4b4b4;
  --notification-1: #a55fb7;
  --notification-2: #c49000;
  --calendar-1: #4eb2fe;
  --calendar-2: #b4a3f6;
  --calendar-3: #02b181;
  --calendar-4: #d4e948;
}

.WaikawaGray.Classic {
  --primary: var(--classicWaikawaGray);
  --primary-variant: #2e4275;
  --secondary: #fbc02d;
  --secondary-variant: #fbc02d;
  --background: #ffffff;
  --error: #b00020;
  --text-1: #000000;
  --text-2: #6a6a6a;
  --text-3: #9f9f9f;
  --design-component-1: #e0e0e0;
  --design-component-2: #f4f4f4;
  --design-component-3: #919191;
  --design-component-4: #b4b4b4;
  --notification-1: #c4dfe6;
  --notification-2: #c7afbd;
  --calendar-1: #65a4ac;
  --calendar-2: #377ea0;
  --calendar-3: #ce5a58;
  --calendar-4: #aebc00;
}

.WaikawaGray.Dark {
  --primary: var(--darkWaikawaGray);
  --primary-variant: #2e4275;
  --secondary: #fbc02d;
  --secondary-variant: #fff59d;
  --background: #121212;
  --error: #cf6679;
  --text-1: #bbbbbb;
  --text-2: #a9a9a9;
  --text-3: #646464;
  --design-component-1: #2b2b2b;
  --design-component-2: #1d1d1d;
  --design-component-3: #a9a9a9;
  --design-component-4: #b4b4b4;
  --notification-1: #c4dfe6;
  --notification-2: #c7afbd;
  --calendar-1: #40c2ca;
  --calendar-2: #52c0ff;
  --calendar-3: #db7e80;
  --calendar-4: #d4e948;
}
